<template>
  <div class="sub-header">
    <div class="sub-header__wrap container">
      <h1 class="sub-header__title main-title">
        {{ title }}
      </h1>
      <div class="sub-header__block flex">
        <div class="sub-header__left flex">
          <!--                    <button class="sub-header__btn-tabs" @click="$router.push('/analytics/accounts')" :class="{'sub-header__active-tabs' : $route.params.type === 'accounts' || !$route.params.type}">Счета</button>-->
          <button
            class="sub-header__btn-tabs"
            :class="{
              'sub-header__active-tabs': $route.params.type === 'products',
            }"
            @click="$router.push('/analytics/products')"
          >
            Заказы
          </button>
          <button
            class="sub-header__btn-tabs"
            :class="{
              'sub-header__active-tabs': $route.params.type === 'costs',
            }"
            @click="$router.push('/analytics/costs')"
          >
            Расходы
          </button>
          <button
            class="sub-header__btn-tabs"
            :class="{
              'sub-header__active-tabs': $route.params.type === 'logistics',
            }"
            @click="$router.push('/analytics/logistics')"
          >
            Тепловая карта
          </button>
          <div v-if="$route.params.type === 'logistics'" class="date-filter">
            <button class="sub-header__btn-tabs" @click="dateShow = !dateShow">
              Период
              <b-icon
                class="filter__item-btn-icon"
                style="color: #707070; margin-left: 5px; vertical-align: middle"
                icon="calendar4-week"
              />
            </button>
            <div v-show="dateShow" class="date-filter__calendar">
              <vc-date-picker
                v-model="range"
                class="add__calendar"
                color="green"
                :rows="1"
                is-range
                @input="inputData()"
              />
              <button class="btn btn-primary mt-2" @click="hideModal()">Применить</button>
              <button class="btn btn-primary mt-2" @click="inputDataClean('')">Очистить</button>
            </div>
          </div>
        </div>
        <!-- <div class="sub-header__right flex"  v-if="$route.params.type === 'costs'">
                   <button class="sub-header__btn default-btn flex">
                       <img class="sub-header__icon" src="@/assets/png/database-download.png" alt="">
                       Экспорт
                   </button>
                   <p class="sub-header__text" > 1-50 из 123 </p>
                   <button class="sub-header__box">
                       <img class="sub-header__box-icon" src="@/assets/png/database-icon.png" alt="">
                   </button>
                   <button class="sub-header__box">
                       <img class="sub-header__box-icon" src="@/assets/png/database-icon-active.png" alt="">
                   </button>
               </div> -->
      </div>
    </div>
    <div v-if="dateShow" class="modal-tint-close" @click="dateShow = false" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      dateShow: false,
      range: [],
      title: "Аналитика",
      showBlock: false,
    };
  },
  watch: {
    "$route.params.type"(to) {
      this.changeRout(to);
    },
  },
  created() {
    this.setQueries()
  },
  mounted() {
    this.changeRout(this.$route.params.type);
  },
  methods: {
    inputData() {
      this.range.start = this.range
        ? new Date(this.range.start).toISOString().split("T")[0]
        : undefined;
      this.range.end = this.range
        ? new Date(this.range.end).toISOString().split("T")[0]
        : undefined;

      // переводим данные в ссылку
      if (this.range.start !== undefined && this.range.end !== undefined)
      {
        const searchURL = new URL(window.location);

        searchURL.searchParams.set('from_date', this.range.start);
        searchURL.searchParams.set('to_date', this.range.end);

        window.history.pushState({}, '', searchURL)
      }
    },
    hideModal() {
      this.$store.commit("dateFilter", this.range);
      this.dateShow = false;
    },
    inputDataClean() {
      this.range = [];
      this.$store.commit("dateFilter", null);
      this.dateShow = false;

      // убираем из ссылки
      const searchURL = new URL(window.location);
      searchURL.searchParams.delete('from_date');
      searchURL.searchParams.delete('to_date');
      window.history.pushState({}, '', searchURL)
    },
    changeRout(n) {
      switch (n) {
        case "costs":
          this.title = "Аналитика";
          break;
        case "logistics":
          this.title = "Аналитика";
          break;
        default:
          this.title = "Аналитика";
          break;
      }
    },
    setQueries() {
      // если в ссылке имеются фильтры, применяем их к стейту
      
      if (this.$route.query.from_date !== undefined && this.$route.query.to_date !== undefined) {

        this.range.start = this.$route.query.from_date;
        this.range.end = this.$route.query.from_date; 
      }
      
      var range = this.range.start ? this.range : null 
      this.$store.commit("dateFilter", range);
    }
  },
};
</script>

<style scoped>
.add__calendar {
  position: initial;
  left: initial;
  top: initial;
  z-index: initial;
}
.date-filter__calendar {
  background-color: #f8f8f8;
  border: 1px solid #dedede;
  box-shadow: 0 0 15px 5px #33333355;
  padding: 0.65rem;
  border-radius: 0.5rem;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
  display: flex;
  flex-direction: column;
}
.modal-tint-close {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 15;
  background-color: hsla(0, 0%, 0%, 48%);
}
.sub-header {
  padding: 20px 0 0 0;
  background-color: #fff;
  border: 1px solid #f2f2f8;
}
.sub-header__block {
  margin: 10px 0 20px 0;
  height: 40px;
}
.sub-header__left {
  margin: 20px 0 0 0;
}
.sub-header__btn-tabs {
  padding: 9px;
  height: 36px;
  background: transparent;
  border-radius: 8px;
  font-weight: bold;
  line-height: 16px;
  outline: none;
}
.sub-header__btn-tabs:not(:last-child) {
  margin-right: 10px;
}
.sub-header__active-tabs {
  background: #e0e9fa;
  color: #306cdc;
}
.sub-header__text {
  margin: 0 20px 0 25px;
  color: #707070;
}
.sub-header__icon {
  margin-right: 12px;
}
.sub-header__box {
  min-width: 36px;
  height: 36px;
  line-height: 16px;
  background: #ffffff;
  border: 1px solid #e0e9fa;
  box-sizing: border-box;
  border-radius: 8px;
}
.sub-header__box:not(:last-child) {
  margin-right: 18px;
}
</style>
